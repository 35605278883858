import i18next from 'i18next';
import merge from 'lodash/merge';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { baseTranslationEn } from 'auto-design-common';
import translationEn from './en/translation.json';
import translationCs from './cs/translation.json';

i18next.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    en: {
      translation: merge(baseTranslationEn, translationEn),
    },
    cs: {
      translation: translationCs,
    },
  },
});
