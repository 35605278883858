// Settings configured here will be merged into the final config object.
export default {
  apiUrl: 'http://localhost:3001/api',
  paypalClientId: 'AY5IsJLP96Yhwh7Sm47T_i-WG4fw7EnwNEI0pheFbzF6Phmu32Fm51BF7VLIxOziFJmEs12Hd2Z25vOA',
  googleClientId: '871238655533-6c1ehr8l3d57ru023p7rvr7nhajf65nj.apps.googleusercontent.com',
  pusherKey: '18ae0063051bb0482307',
  pusherCluster: 'ap1',
  tenantId: process.env.REACT_APP_TENANT_ID,
  language: 'en',
  privacyPolicyUrl: 'https://openstudio.cz/ochrana-osobnich-udaju',
  termsOfServicesUrl: 'https://openstudio.cz/vseobecne-obchodni-podminky',
  instructionUrl: 'https://openstudio.cz/navod',
  disablePanoImage: true,
  disableEmailLogin: true,
  instruction2dVideo: 'https://www.youtube.com/watch?v=3oPMt42JymA',
};
